import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
//import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { withTranslation } from 'react-i18next'

import '../GlistenOrderSummary/ordersummary.scss'

const GlistenOrderSummary = (props) => {
  console.log(props.items)
  return (
    <>
      <h5
        style={{ marginTop: '1.875rem', textDecoration: 'underline' }}
        className='order-sum'
      >
        {props.t('Order Summary')}
      </h5>
      <div className='table-rep-plugin'>
        <div className='table-responsive mb-0' data-pattern='priority-columns'>
          <table id='tech-companies-1' className='table table-border'>
            <thead>
              <tr>
                <th
                  style={{
                    paddingLeft: '1.688rem',
                    paddingBottom: '0',
                    paddingTop: '0.25rem',
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                    fontSize: '0.75rem',
                    letterSpacing: '0.084rem',
                    lineHeight: '2.25rem',
                    color: '#000000',
                  }}
                >
                  {props.t('Service')}
                </th>
                <th
                  style={{
                    textAlign: 'right',
                    paddingRight: '2.25rem',
                    paddingBottom: '0',
                    paddingTop: '0.25rem',
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                    fontSize: '0.75rem',
                    letterSpacing: '0.084rem',
                    lineHeight: '2.25rem',
                    color: '#000000',
                  }}
                  data-priority='1'
                >
                  {props.t('Price')}
                </th>
              </tr>
            </thead>
            <tbody>
              {props.items.map((item, idx) => (
                <tr className='no-border' key={idx}>
                  <td
                    className='no-border'
                    style={{
                      paddingLeft: '1.688rem',
                      paddingTop: '1rem',
                      paddingBottom: '1rem',
                      fontFamily: 'Poppins',
                      fontWeight: '400',
                      fontSize: '0.75rem',
                      letterSpacing: '0.084rem',
                      lineHeight: '1.25rem',
                      color: '#000000',
                    }}
                  >
                    {item.name}
                  </td>
                  <td
                    className='no-border'
                    style={{
                      textAlign: 'right',
                      paddingTop: '1rem',
                      paddingBottom: '1rem',
                      paddingRight: '2.125rem',
                      fontFamily: 'Poppins',
                      fontWeight: '400',
                      fontSize: '0.75rem',
                      letterSpacing: '0.084rem',
                      lineHeight: '1.25rem',
                      color: '#000000',
                    }}
                  >
                    <NumberFormat
                      value={item.retail_cost}
                      displayType={'text'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </td>
                </tr>
              ))}

              {/* Empty Cart */}
              {props.items.length === 0 ? (
                <div
                  style={{
                    paddingTop: '10%',
                    paddingBottom: '10%',
                    paddingLeft: '15%',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    letterSpacing: '0.084rem',
                    color: '#777777',
                  }}
                >
                  {props.t('Your Cart is Empty')}
                </div>
              ) : (
                <></>
              )}

              <tr className='border-top'>
                <td
                  style={{
                    textAlign: 'right',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    letterSpacing: '0.084rem',
                    lineHeight: '2.25rem',
                    color: '#000000',
                  }}
                >
                  {props.t('Total')}
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    paddingTop: '0.75rem',
                    paddingBottom: '1rem',
                    paddingRight: '2.063rem',
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                    fontSize: '1.125rem',
                    letterSpacing: '0.127rem',
                    lineHeight: '2.25rem',
                    color: '#000000',
                  }}
                >
                  <NumberFormat
                    value={props.items
                      .map((item) => item.retail_cost)
                      .reduce((prev, curr) => prev + curr, 0)}
                    displayType={'text'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={true}
                    style={{ fontWeight: '600', fontSize: '1.2rem' }}
                    prefix={'$'}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

GlistenOrderSummary.propTypes = {
  t: PropTypes.any,
  items: PropTypes.any,
}

export default withTranslation()(GlistenOrderSummary)
