/* eslint-disable no-unused-vars */
import PropTypes, { func } from 'prop-types'
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Media,
} from 'reactstrap'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { getOrderByKey } from 'store/actions'
import {
  formatDate,
  formatTimeOnly,
  isToday,
  isTomrrow,
  isLate,
} from '../../helpers/date_formatter'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import GlistenUpdatesBox from '../../components/GlistenUpdatesBox'
import GlistenOrderHistory from '../../components/GlistenOrderHistoryTable'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import Avatar from 'react-avatar'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import nocarimage from '../../assets/images/no-car-image.svg'
import LoadingOverlay from 'react-loading-overlay'
import { ReactComponent as AddPhotoIcon } from '../../assets/images/ic-add_photo.svg'
//import { ReactComponent as DeleteIcon } from '../../assets/images/ic-delete.svg'
import details_icon from '../../assets/images/ic-delete.svg'
import {
  approveOrder,
  assignWork,
  startWorkOrder,
  endWorkOrder,
  rejectNewOrder,
  reviewWorkOrder,
  uploadImage,
  deleteImage,
} from 'store/actions'

import '../ServiceOrderDetail/serviceorderdetail.scss'
import { sendPageViewEvent } from '../../firebase'
import StartEndReviewCfrmModal from '../Whiteboard/StartEndReviewCfrmModal'
import ApproveCfrmModal from '../Whiteboard/ApproveCfrmModal'
import AssignCfrmModal from '../Whiteboard/AssignCnfrmModal'
import Compress from 'compress.js'

const ServiceOrderDetail = (props) => {
  useEffect(() => {
    sendPageViewEvent('Order Detail')
  }, [])

  const { onGetOrderByKey } = props
  const parsed = queryString.parse(props.location.search)

  useEffect(() => {
    onGetOrderByKey(props.org, props.tenant, parsed.orderId)
    props.setShowNavBack(true)
  }, [onGetOrderByKey])

  var title = props.t('Order Detail')
  props.setPageTitle(title)
  var _imageGallery
  const [showNavArrows, setShowNavArrows] = useState(false)

  const _onScreenChange = () => {
    setShowNavArrows(!showNavArrows)
  }

  const _customAction = () => {
    props.onDeleteImage(
      props.org,
      props.tenant,
      props.orderDetail.data_key,
      props.orderDetail.vehicle.data_key,
      props.orderDetail.images[_imageGallery.getCurrentIndex()].data_key
    )
  }

  const _renderCustomControl = () => {
    return (
      <Button
        className={
          !showNavArrows
            ? 'hide inpt-icon image-gallery-icon image-gallery-play-button'
            : 'inpt-icon image-gallery-icon image-gallery-play-button'
        }
        onClick={_customAction.bind(this)}
      >
        <Media
          src={details_icon}
          style={{
            height: '2.25rem',
          }}
        ></Media>
      </Button>
    )
  }

  const [selectedOrder, setSelectedOrder] = useState()
  const [selectedOrderTitle, setSelectedOrderTitle] = useState('')
  const [selectedOrderBtn, setSelectedOrderBtn] = useState('')
  const [modal_approve, setmodal_approve] = useState(false)
  const [modal_assign, setmodal_assign] = useState(false)
  const [modal_startendreview, setmodal_startendreview] = useState(false)

  const getActionElement = () => {
    const order = props.orderDetail

    const cardAcceptReject = () => {
      var title =
        props.t('Requested by ') +
        order.created_by.first_name +
        ' ' +
        order.created_by.last_name +
        ' - ' +
        order.created_by.department
      setmodal_approve(!modal_approve)
      setSelectedOrder(props.orderDetail)
      setSelectedOrderTitle(title)
      setSelectedOrderBtn(props.t('Submit'))
    }

    const cardAssign = () => {
      setmodal_assign(!modal_assign)
      setSelectedOrder(props.orderDetail)
      setSelectedOrderTitle(props.t('Assign Detailer to Order'))
      setSelectedOrderBtn(props.t('Assign'))
    }

    const cardStartJob = () => {
      setmodal_startendreview(!modal_startendreview)
      setSelectedOrder(props.orderDetail)
      setSelectedOrderTitle(props.t('Start Job'))
      setSelectedOrderBtn(props.t('Start Job'))
    }

    const cardEndJob = () => {
      setmodal_startendreview(!modal_startendreview)
      setSelectedOrder(props.orderDetail)
      setSelectedOrderTitle(props.t('End Job'))
      setSelectedOrderBtn(props.t('End Job'))
    }

    const cardReviewWork = () => {
      var title =
        props.t('Work Completed by ') +
        props.orderDetail.assign_to.first_name +
        ' ' +
        props.orderDetail.assign_to.last_name
      setmodal_startendreview(!modal_startendreview)
      setSelectedOrder(props.orderDetail)
      setSelectedOrderTitle(title)
      setSelectedOrderBtn(props.t('Approve Complete'))
    }

    return (
      <Row className='row-margin-fix'>
        <Col
          lg='12'
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0rem',
          }}
        >
          {(props.userFeat.includes('ftl-manager') ||
            props.userFeat.includes('ftl-executive') ||
            props.userFeat.includes('ftl-admin')) &&
            order &&
            order.status == 'New' && (
              <Button
                className='prim-cta-bgr btn glisten-btn'
                style={{ marginBottom: '3.125rem' }}
                onClick={() => {
                  cardAcceptReject()
                }}
              >
                {props.t('APPROVE')} / {props.t('REJECT')}
              </Button>
            )}

          {props.userFeat.includes('ftl-detailshop') &&
            (props.userFeat.includes('ftl-manager') ||
              props.userFeat.includes('ftl-executive') ||
              props.userFeat.includes('ftl-admin')) &&
            order &&
            (order.status == 'Approved' || order.status == 'New') && (
              <Button
                className='prim-cta-bgr btn glisten-btn'
                style={{ marginBottom: '3.125rem' }}
                onClick={() => {
                  cardAssign()
                }}
              >
                {props.t('ASSIGN')}
              </Button>
            )}

          {props.userFeat.includes('ftl-detailshop') &&
            props.userFeat.includes('ftl-employee') &&
            order &&
            order.status == 'WorkAssigned' &&
            props.username == order.assign_to.username && (
              <Button
                className='prim-cta-bgr btn glisten-btn'
                style={{ marginBottom: '3.125rem' }}
                onClick={() => {
                  cardStartJob()
                }}
              >
                {props.t('START JOB')}
              </Button>
            )}

          {/* TO DO: If admin stops order, updates should reflect that */}
          {props.userFeat.includes('ftl-detailshop') &&
            (props.userFeat.includes('ftl-employee') ||
              props.userFeat.includes('ftl-admin')) &&
            order &&
            order.status == 'WorkStarted' &&
            (props.username == order.assign_to.username ||
              props.userFeat.includes('ftl-admin')) && (
              <Button
                className='prim-cta-bgr btn glisten-btn'
                style={{ marginBottom: '3.125rem' }}
                onClick={() => {
                  cardEndJob()
                }}
              >
                {props.t('END JOB')}
              </Button>
            )}

          {props.userFeat.includes('ftl-detailshop') &&
            (props.userFeat.includes('ftl-manager') ||
              props.userFeat.includes('ftl-executive') ||
              props.userFeat.includes('ftl-admin')) &&
            order &&
            order.status == 'WorkEnded' && (
              <Button
                className='prim-cta-bgr btn glisten-btn'
                style={{ marginBottom: '3.125rem' }}
                onClick={() => {
                  cardReviewWork()
                }}
              >
                {props.t('REVIEW WORK')}
              </Button>
            )}
        </Col>
      </Row>
    )
  }

  const vehicleInformation = () => {
    const order = props.orderDetail
    return (
      <Row style={{ marginTop: '2rem' }}>
        <Col lg='12'>
          <h5 className='ord-subheader'>{props.t('Vehicle Information')}</h5>
          <Card
            style={{
              marginBottom: '0',
              boxShadow: '0 2px 7px 0  rgba(0, 0, 0, 0.18)',
            }}
          >
            <CardBody style={{ paddingBottom: '1.875rem' }}>
              {/* MAKE */}
              <Row className='row-margin-fix'>
                <Col
                  lg='12'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '0rem',
                  }}
                >
                  <Label className='vech-info-row-title'>
                    • {props.t('Car Make')}
                    {'\u003A'}
                  </Label>
                  <Label className='vech-info-row-data'>
                    {order && order.vehicle.make}
                  </Label>
                </Col>
              </Row>
              {/* MODEL */}
              <Row className='row-margin-fix'>
                <Col
                  lg='12'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '0rem',
                  }}
                >
                  <Label className='vech-info-row-title'>
                    • {props.t('Car Model')}
                    {'\u003A'}
                  </Label>
                  <Label className='vech-info-row-data'>
                    {order && order.vehicle.model}
                  </Label>
                </Col>
              </Row>
              {/* YEAR */}
              <Row className='row-margin-fix'>
                <Col
                  lg='12'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '0rem',
                  }}
                >
                  <Label className='vech-info-row-title'>
                    • {props.t('Year')}
                    {'\u003A'}
                  </Label>
                  <Label className='vech-info-row-data'>
                    {order && order.vehicle.year}
                  </Label>
                </Col>
              </Row>
              {/* COLOR */}
              <Row className='row-margin-fix'>
                <Col
                  lg='12'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '0rem',
                  }}
                >
                  <Label className='vech-info-row-title'>
                    • {props.t('Color')}
                    {'\u003A'}
                  </Label>
                  <Label className='vech-info-row-data'>
                    {order && order.vehicle.color}
                  </Label>
                </Col>
              </Row>
              {/* CUSTOMER TAG */}
              {order && order.customer_tag ? (
                <Row className='row-margin-fix'>
                  <Col
                    lg='12'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '0rem',
                    }}
                  >
                    <Label className='vech-info-row-title'>
                      • {props.t('Customer Tag')}
                      {'\u003A'}
                    </Label>
                    <Label className='vech-info-row-data'>
                      {order && order.customer_tag}
                    </Label>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {/* STOCK NUMBER */}
              {order && order.stock ? (
                <Row className='row-margin-fix'>
                  <Col
                    lg='12'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '0rem',
                    }}
                  >
                    <Label className='vech-info-row-title'>
                      • {props.t('Stock Number')}
                      {'\u003A'}
                    </Label>
                    <Label className='vech-info-row-data'>
                      {order && order.stock}
                    </Label>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {/* LAST 6 Of VIN */}
              <Row className='row-margin-fix'>
                <Col
                  lg='12'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '0rem',
                  }}
                >
                  <Label className='vech-info-row-title'>
                    • {props.t('Last 6 of VIN')}
                    {'\u003A'}
                  </Label>
                  <Label className='vech-info-row-data'>
                    {order && order.vehicle.data_key.slice(-6)}
                  </Label>
                </Col>
              </Row>
              {/* PO NUMBER */}
              {order && order.po_number ? (
                <Row className='row-margin-fix'>
                  <Col
                    lg='12'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '0rem',
                    }}
                  >
                    <Label className='vech-info-row-title'>
                      • {props.t('PO Number')}
                      {'\u003A'}
                    </Label>
                    <Label className='vech-info-row-data'>
                      {order && order.po_number}
                    </Label>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  const requestInformation = () => {
    const order = props.orderDetail

    const priorityLabel = (idx) => {
      switch (idx) {
        case '1':
          return props.t('Waiter')
        case '2':
          return props.t('Spot Delivery')
        case '3':
          return props.t('End of Day')
        case '4':
          return props.t('Fill')
        case '5':
          return props.t('Appointment')
        default:
          return ''
      }
    }

    return (
      <Row style={{ marginTop: '3.125rem' }}>
        <Col lg='12'>
          <h5 className='ord-subheader'>{props.t('Request Information')}</h5>
          <Card>
            <CardBody
              style={{
                paddingBottom: '1.875rem',
                boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.18)',
              }}
            >
              {/* REQUESTED BY */}
              {order && order.created_by ? (
                <Row className='row-margin-fix'>
                  <Col
                    lg='12'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0rem',
                    }}
                  >
                    <Label className='request-line-1'>
                      {props.t('REQUESTED BY')}
                    </Label>
                    <Label className='request-line-2'>
                      {order.created_by.first_name +
                        ' ' +
                        order.created_by.last_name}
                    </Label>
                    <Label className='request-line-3'>
                      {order.created_by.department +
                        ' ' +
                        props.t('Department')}
                    </Label>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {/* APPROVED BY */}
              {/* TODO: Have Evan add this information form backend then display */}

              {/* REQUESTED FOR */}
              {order && order.pickup_date_time ? (
                <Row className='row-margin-fix'>
                  <Col
                    lg='12'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0rem',
                    }}
                  >
                    <Label className='request-line-1'>
                      {props.t('REQUESTED FOR')}
                    </Label>
                    <Label className='request-line-2'>
                      {`${formatDate(order.pickup_date_time)}`}
                    </Label>
                    <Label className='request-line-3'>
                      {order && priorityLabel(order.priority)}
                    </Label>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  const serviceOrderTitle = () => {
    const order = props.orderDetail

    return (
      <Row className='row-margin-fix'>
        <Col
          lg='12'
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0rem',
          }}
        >
          <Label className='identifier-title'>
            {order && order.customer_tag
              ? order.customer_tag
              : order && order.stock
              ? order.stock
              : order && order.vehicle.data_key
              ? order.vehicle.data_key
              : '00000'}
          </Label>
          <Label className='identifier-subtitle'>
            {order && order.customer_tag
              ? props.t('[CUSTOMER TAG]')
              : order && order.stock
              ? props.t('[STOCK NUMBER]')
              : order && order.vehicle.data_key
              ? props.t('[VIN]')
              : '[No Identifier]'}
          </Label>
        </Col>
      </Row>
    )
  }

  const serviceOrderStatus = () => {
    const order = props.orderDetail

    const priorityLabel = (idx) => {
      switch (idx) {
        case '1':
          return props.t('WAITER - ')
        case '2':
          return props.t('SPOT DELIVERY - ')
        case '3':
          return props.t('EOD - ')
        case '4':
          return props.t('FILL - ')
        case '5':
          return props.t('APPOINTMENT - ')
        default:
          return ''
      }
    }

    const dueTomrrwMorn = (due_date) => {
      var tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)

      if (isTomrrow(due_date)) {
        if (due_date.getHours() == 10) {
          if (due_date.getMinutes() <= 29) {
            return true
          } else {
            return false
          }
        } else if (due_date.getHours() < 10) {
          return true
        }
      } else {
        return false
      }
    }

    // Checks if order is due today OR before 10:30AM the following day OR is currently late
    const isDueSoon = (pickup_date) => {
      if (
        isToday(pickup_date) ||
        dueTomrrwMorn(pickup_date) ||
        isLate(pickup_date)
      ) {
        return true
      } else {
        return false
      }
    }

    const dueDateLabel = (pickup_date) => {
      // Due date is current day
      if (isToday(pickup_date)) {
        return props.t('TODAY ') + `${formatTimeOnly(pickup_date)}`
      }
      // Due date is tomorrow
      else if (isTomrrow(pickup_date)) {
        return props.t('TOMORROW ') + `${formatTimeOnly(pickup_date)}`
      }
      // Due date is any day after tomorrow aka future due date or has passed
      else {
        return formatDate(order.pickup_date_time)
      }
    }

    return (
      <Row className='row-margin-fix'>
        <Col
          lg='12'
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0rem',
          }}
        >
          <h5
            className='ord-subheader'
            style={!isMobile ? { marginLeft: '3.75rem' } : {}}
          >
            {props.t('Status')}
          </h5>
          <Card
            style={
              !isMobile
                ? {
                    marginLeft: '3.75rem',
                    marginRight: '3.75rem',
                    marginBottom: '3.438rem',
                    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.18)',
                  }
                : {
                    marginBottom: '3.438rem',
                    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.18)',
                  }
            }
          >
            <CardBody
              style={{
                paddingTop: '1.438rem',
                paddingBottom: '1.438rem',
                paddingLeft: '1.25rem',
                paddingRight: '1.25rem',
              }}
            >
              <Row>
                <Col xl='6' style={{ paddingRight: '0.625rem' }}>
                  {order &&
                    order.status == 'New' &&
                    props.userFeat.includes('ftl-dealership') && (
                      <Label className='yellow-status-bg'>
                        {props.t('NEEDS APPROVAL')}
                      </Label>
                    )}

                  {/* {order && order.status == 'WorkRejected' && (
                    <Label className='white-status-bg'>
                      {props.t('ORDER REJECTED')}
                    </Label>
                  )} */}

                  {order &&
                  order.status == 'Approved' &&
                  props.userFeat.includes('ftl-dealership') ? (
                    <Label className='gray-status-bg'>
                      {props.t('ORDER SUBMITTED')}
                    </Label>
                  ) : (
                    order &&
                    (order.status == 'Approved' || order.status == 'New') &&
                    props.userFeat.includes('ftl-detailshop') && (
                      <Label className='gray-status-bg'>
                        {props.t('NO DETAILERS ASSIGNED')}
                      </Label>
                    )
                  )}

                  {order &&
                  order.status == 'WorkAssigned' &&
                  props.userFeat.includes('ftl-dealership') ? (
                    <Label className='gray-status-bg'>
                      {props.t('ORDER SUBMITTED')}
                    </Label>
                  ) : (
                    order &&
                    order.status == 'WorkAssigned' &&
                    props.userFeat.includes('ftl-detailshop') && (
                      <Label className='yellow-status-bg'>
                        {props.t('NOT STARTED')}
                      </Label>
                    )
                  )}

                  {order && order.status == 'WorkStarted' && (
                    <Label className='green-status-bg'>
                      {props.t('IN PROGRESS')}
                    </Label>
                  )}

                  {order &&
                  order.status == 'WorkEnded' &&
                  props.userFeat.includes('ftl-dealership') ? (
                    <Label className='green-status-bg'>
                      {props.t('BEING REVIEWED')}
                    </Label>
                  ) : (
                    order &&
                    order.status == 'WorkEnded' &&
                    props.userFeat.includes('ftl-detailshop') && (
                      <Label className='yellow-status-bg'>
                        {props.t('READY FOR REVIEW')}
                      </Label>
                    )
                  )}

                  {order &&
                    (order.status == 'WorkReviewed' ||
                      order.status == 'Completed') && (
                      <Label className='white-status-bg'>
                        {props.t('COMPLETED')}
                      </Label>
                    )}

                  {order && order.status == 'Rejected' && (
                    <Label className='white-status-bg'>
                      {props.t('ORDER REJECTED')}
                    </Label>
                  )}
                </Col>
                <Col xl='6' style={{ paddingLeft: '0.625' }}>
                  <Label
                    className={
                      order &&
                      (order.status == 'Completed' ||
                        order.status == 'WorkReviewed')
                        ? order &&
                          order.data_timestamp < order.pickup_date_time * 1000
                          ? 'done-status-bg'
                          : 'done-late-status-bg'
                        : order && order.status == 'Rejected'
                        ? 'white-status-bg'
                        : order &&
                          isDueSoon(new Date(order.pickup_date_time * 1000))
                        ? 'red-status-bg'
                        : 'gray-status-bg'
                    }
                  >
                    {order &&
                      !(order.status == 'Rejected') &&
                      priorityLabel(order.priority)}
                    {order &&
                    (order.status == 'Completed' ||
                      order.status == 'WorkReviewed')
                      ? order &&
                        order.data_timestamp < order.pickup_date_time * 1000
                        ? props.t('ON TIME')
                        : props.t('LATE')
                      : order && order.status == 'Rejected'
                      ? ''
                      : order &&
                        dueDateLabel(new Date(order.pickup_date_time * 1000))}
                  </Label>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  const customerNote = () => {
    const order = props.orderDetail

    if (order && order.note) {
      return (
        <>
          <Row className='row-margin-fix'>
            <Col
              lg='12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
              }}
            >
              <h5
                className='ord-subheader'
                style={!isMobile ? { marginLeft: '3.75rem' } : {}}
              >
                {props.t('Customer Note')}
              </h5>
              <Card
                style={
                  !isMobile
                    ? {
                        marginLeft: '3.75rem',
                        marginRight: '3.75rem',
                        marginBottom: '3.438rem',
                        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.18)',
                      }
                    : {
                        marginBottom: '3.438rem',
                        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.18)',
                      }
                }
              >
                <CardBody
                  style={{
                    paddingLeft: '2.5rem',
                    paddingRight: '2.5rem',
                    paddingBottom: '1.875rem',
                    paddingTop: '1.875rem',
                  }}
                >
                  <Row className='row-margin-fix'>
                    <Col
                      lg='12'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0rem',
                      }}
                    >
                      <Label className='note-text'>{order.note}</Label>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )
    }
  }

  const assignedDetailers = () => {
    const order = props.orderDetail

    if (order && order.assign_to) {
      return (
        <>
          <Row className='row-margin-fix'>
            <Col
              lg='12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
              }}
            >
              <h5
                className='ord-subheader'
                style={!isMobile ? { marginLeft: '3.75rem' } : {}}
              >
                {props.t('Assigned Detailers')}
              </h5>
              <Card
                style={
                  !isMobile
                    ? {
                        marginLeft: '3.75rem',
                        marginRight: '3.75rem',
                        marginBottom: '3.438rem',
                        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.18)',
                      }
                    : {
                        marginBottom: '3.438rem',
                        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.18)',
                      }
                }
              >
                <CardBody
                  style={{
                    padding: 0,
                  }}
                >
                  <Row className='row-margin-fix'>
                    <Col
                      lg='6'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0rem',
                      }}
                    >
                      {/* Detailer(s) List */}
                      <Row className='row-margin-fix'>
                        <Col
                          lg='2'
                          key={order.assign_to}
                          style={{
                            paddingTop: '15%',
                            paddingBottom: '15%',
                            paddingLeft: '0',
                            marginLeft: '4rem',
                          }}
                        >
                          <Avatar
                            className='assigned-avatar'
                            color='#D0D0D0'
                            fgColor='#000000'
                            size='2.75rem'
                            round={true}
                            name={
                              order.assign_to.first_name +
                              ' ' +
                              order.assign_to.last_name
                            }
                          ></Avatar>
                        </Col>
                        <Col
                          lg='6'
                          key={order.assign_to}
                          className={'assigned-name'}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0',
                          }}
                        >
                          {order.assign_to.first_name}{' '}
                          {order.assign_to.last_name}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      lg='1'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '0',
                      }}
                    >
                      {/* divider */}
                      {/* <div
                        //className='align-self-center'
                        style={{
                          height: '70%',
                          width: '0.063rem',
                          background: '#D0D0D0',
                          // marginTop: '15%',
                          // marginBottom: '15%'
                        }}
                      ></div> */}
                    </Col>

                    <Col
                      lg='5'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0rem',
                      }}
                    >
                      {/* TO DO: JOB STOP WATCH WILL GO HERE */}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>

        // <>
        //   <Row className='row-margin-fix'>
        //     <Col lg='12'>
        //       <h5>{props.t('Assigned Detailers')}</h5>
        //       <Card>
        //         <CardBody>
        // <Row>
        //   <Col lg='1' key={order.assign_to}>
        //     <Avatar
        //       className='avatar'
        //       color='#D0D0D0'
        //       fgColor='#000000'
        //       size='2rem'
        //       round={true}
        //       name={
        //         order.assign_to.first_name +
        //         ' ' +
        //         order.assign_to.last_name
        //       }
        //     ></Avatar>
        //   </Col>
        //   <Col
        //     lg='5'
        //     key={order.assign_to}
        //     style={{ display: 'flex', alignItems: 'center' }}
        //   >
        //     {order.assign_to.first_name} {order.assign_to.last_name}
        //   </Col>
        // </Row>
        //         </CardBody>
        //       </Card>
        //     </Col>
        //   </Row>
        // </>
      )
    }
  }

  const updateOrderServices = () => {
    const order = props.orderDetail

    if (order && order.comments) {
      return (
        <>
          <Row
            style={{
              marginTop: '1.875rem',
              marginLeft: '0rem',
              marginRight: '0rem',
            }}
          >
            <Col
              lg='12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
              }}
            >
              <h5
                className='ord-subheader'
                style={!isMobile ? { marginLeft: '3.75rem' } : {}}
              >
                {props.t('Updates')}
              </h5>
              <GlistenUpdatesBox canSendUpdate={true} orderDetail={order} />
            </Col>
          </Row>
        </>
      )
    }
  }

  const orderHistory = () => {
    const order = props.orderDetail
    if (
      order &&
      order.vehicle &&
      order.vehicle.order &&
      order.vehicle.order.length > 0 &&
      !(order.status == 'Completed' || order.status == 'WorkReviewed') &&
      (props.userFeat.includes('ftl-dealership') ||
        (props.userFeat.includes('ftl-detailshop') &&
          !props.userFeat.includes('ftl-employee')))
    ) {
      return (
        <>
          <Row
            style={{
              marginTop: '1.875rem',
              marginLeft: '0rem',
              marginRight: '0rem',
            }}
          >
            <Col
              lg='12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
              }}
            >
              <h5
                className='ord-subheader'
                style={!isMobile ? { marginLeft: '3.75rem' } : {}}
              >
                {props.t('Order History')}
              </h5>
              <Card
                style={
                  !isMobile
                    ? {
                        marginLeft: '3.75rem',
                        marginRight: '3.75rem',
                        marginBottom: '3.438rem',
                        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.18)',
                      }
                    : {
                        marginBottom: '3.438rem',
                        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.18)',
                      }
                }
              >
                <CardBody
                  style={{
                    padding: '0rem',
                  }}
                >
                  <GlistenOrderHistory
                    history={order && order.vehicle && order.vehicle.order}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )
    }
  }

  const requestedServices = () => {
    const order = props.orderDetail

    const assignGroupImg = (group_name) => {
      if (group_name.replaceAll(' ', '').toUpperCase() == 'CARWASH') {
        return 'illust-icons/ic-car wash.svg'
      } else if (
        group_name.replaceAll(' ', '').toUpperCase() == 'SERVICEDETAIL'
      ) {
        return 'illust-icons/ic-service detail.svg'
      } else if (
        group_name.replaceAll(' ', '').toUpperCase() == 'CARDELIVERY'
      ) {
        return 'illust-icons/ic-car delivery.svg'
      } else if (group_name.replaceAll(' ', '').toUpperCase() == 'POLISH') {
        return 'illust-icons/ic-polish.svg'
      } else if (group_name.replaceAll(' ', '').toUpperCase() == 'NEWCARPREP') {
        return 'illust-icons/ic-new car prep.svg'
      } else if (
        group_name.replaceAll(' ', '').toUpperCase() == 'COMPLETERECONDITION'
      ) {
        return 'illust-icons/ic-complete recondition.svg'
      } else {
        return 'illust-icons/ic-custom category.svg'
      }
    }

    if (order && order.service_catalog) {
      return (
        <Row className='row-margin-fix'>
          <Col
            lg='12'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <h5
              className='ord-subheader'
              style={!isMobile ? { marginLeft: '3.75rem' } : {}}
            >
              {props.t('Requested Services')}
            </h5>
            {order.service_catalog.map((item, idx) => {
              return (
                <Card key={idx} className='service-card '>
                  <CardBody style={{ padding: 0 }}>
                    <div
                      className='container'
                      style={{
                        display: 'flex',
                        alignItems: 'start',
                        justifyContent: 'start',
                      }}
                    >
                      <div className='image' style={{ flexBasis: '20%' }}>
                        <Media
                          src={assignGroupImg(item.category)}
                          style={{ maxWidth: '100%' }}
                          className='service-group-img'
                        ></Media>
                      </div>
                      <div className='text' style={{ paddingTop: '1.8rem' }}>
                        <span className='service-line-1'>{item.name}</span>
                        <br />
                        <span className='service-line-2'>
                          {item.description}
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )
            })}
          </Col>
        </Row>
      )
    }
  }

  const photoElement = () => {
    const order = props.orderDetail

    return (
      order && (
        <Row style={{ marginTop: '1rem' }}>
          <Col lg='12'>
            <Card
              style={{
                marginBottom: '0',
                padding: '0',
                boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.18)',
              }}
            >
              <CardBody style={{ paddingBottom: '1.875rem' }}>
                <Row style={{ marginTop: '1rem' }}>
                  <Col
                    lg='12'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {order.images.length == 0 ? (
                      <Media
                        src={nocarimage}
                        style={{
                          display: 'block',
                          maxWidth: '100%',
                          height: 'auto',
                          width: '93%',
                          borderRadius: '8px',
                          alignSelf: 'center',
                        }}
                      ></Media>
                    ) : (
                      <ImageGallery
                        ref={(i) => (_imageGallery = i)}
                        items={order.images.map((item) => item.image_url)}
                        showThumbnails={!(order.images.length == 1)}
                        showFullscreenButton={true}
                        showPlayButton={false}
                        showNav={showNavArrows}
                        showBullets={false}
                        infinite={false}
                        showIndex={true}
                        onScreenChange={_onScreenChange.bind(this)}
                        renderCustomControls={_renderCustomControl}
                      />
                    )}
                  </Col>
                </Row>
                <Row style={{ marginTop: '1.5rem' }}>
                  <Col
                    lg='12'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0rem',
                      alignContent: 'center',
                    }}
                  >
                    {props.userFeat.includes('ftl-dealership') ||
                    order.status == 'Completed' ||
                    order.status == 'WorkReviewed' ? (
                      <></>
                    ) : (
                      <>
                        <input id='fileButton' type='file' hidden />
                        <Button
                          type='file'
                          className='secondary-btn-sm'
                          style={{ marginBottom: '0.5rem' }}
                          onClick={() => {
                            document.getElementById('fileButton').click()
                            document.getElementById(
                              'fileButton'
                            ).onchange = () => {
                              var files = [
                                ...document.getElementById('fileButton').files,
                              ]
                              files.map((item) => console.log(item))

                              Promise.all([
                                new Compress().compress(files, {
                                  quality: 1, // the quality of the image, max is 1,
                                  maxWidth: 1920, // the max width of the output image, defaults to 1920px
                                  maxHeight: 1920, // the max height of the output image, defaults to 1920px
                                  resize: true, // defaults to true, set false if you do not want to resize the image width and height
                                  rotate: false, // See the rotation section below
                                }),
                                new Compress().compress(files, {
                                  quality: 0.75, // the quality of the image, max is 1,
                                  maxWidth: 640, // the max width of the output image, defaults to 1920px
                                  maxHeight: 640, // the max height of the output image, defaults to 1920px
                                  resize: true, // defaults to true, set false if you do not want to resize the image width and height
                                  rotate: false, // See the rotation section below
                                }),
                              ]).then((results) => {
                                props.onUploadImage(
                                  props.org,
                                  props.tenant,
                                  props.orderDetail.data_key,
                                  props.orderDetail.vehicle.data_key,
                                  results[0][0].data,
                                  results[1][0].data
                                )
                              })
                            }
                          }}
                        >
                          {props.t('ADD PHOTOS')}
                          <AddPhotoIcon
                            style={{
                              marginLeft: '0.813rem',
                              marginBottom: '0.188rem',
                            }}
                          />
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )
    )
  }

  return (
    <React.Fragment>
      {!isMobile ? (
        <div className='breadsrumb-content'>
          <Breadcrumbs
            title={props.t('Order Detail')}
            breadcrumbItem={title}
            showBack={true}
          />
        </div>
      ) : (
        <div style={{ paddingTop: '2rem' }} />
      )}

      <div
        className='page-content'
        style={
          !isMobile
            ? { paddingLeft: '0', paddingRight: '0' }
            : { paddingTop: '2rem' }
        }
      >
        <MetaTags>
          <title>
            {props.t('Home')} | {title}
          </title>
        </MetaTags>
        <LoadingOverlay active={props.loading} spinner>
          <Container
            fluid
            style={!isMobile ? { paddingLeft: '40px', maxWidth: '100%' } : {}}
          >
            <Row>
              <Col xl='3'>
                {photoElement()}
                {vehicleInformation()}
                {props.userFeat.includes('ftl-dealership') ||
                !props.userFeat.includes('ftl-employee') ? (
                  requestInformation()
                ) : (
                  <></>
                )}
              </Col>
              <Col xl='6'>
                {!isMobile ? serviceOrderTitle() : <></>}
                {getActionElement()}
                {serviceOrderStatus()}
                {assignedDetailers()}
                {customerNote()}
                {requestedServices()}
                {orderHistory()}
              </Col>
              <Col
                xl='3'
                style={{
                  boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.18)',
                  backgroundColor: 'white',
                }}
              >
                {updateOrderServices()}
              </Col>
            </Row>
          </Container>
        </LoadingOverlay>
      </div>

      {/* Order Action Confirmation Modal for "Approve New Order"*/}
      <ApproveCfrmModal
        title={selectedOrderTitle}
        submitBtnTitle={selectedOrderBtn}
        isOpenApprove={modal_approve}
        showModalApprove={setmodal_approve}
        order={selectedOrder}
      />

      {/* Order Action Confirmation Modal for "Assign Detail to Order"*/}
      <AssignCfrmModal
        title={selectedOrderTitle}
        submitBtnTitle={selectedOrderBtn}
        isOpenAssign={modal_assign}
        showModalAssign={setmodal_assign}
        order={selectedOrder}
      />

      {/* Order Action Confirmation Modal for "Start Job / End Job / Final Review"*/}
      <StartEndReviewCfrmModal
        title={selectedOrderTitle}
        submitBtnTitle={selectedOrderBtn}
        isOpenStartEndReview={modal_startendreview}
        showModalStartEndReview={setmodal_startendreview}
        order={selectedOrder}
      />
    </React.Fragment>
  )
}

ServiceOrderDetail.propTypes = {
  loading: PropTypes.any,
  t: PropTypes.any,
  setPageTitle: PropTypes.func,
  setShowNavBack: PropTypes.func,
  location: PropTypes.any,
  orderDetail: PropTypes.any,
  onGetOrderByKey: PropTypes.func,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  role: PropTypes.string,
  department: PropTypes.string,
  username: PropTypes.any,
  onApproveOrder: PropTypes.func,
  onAssignOrder: PropTypes.func,
  onStartWorkOrder: PropTypes.func,
  onEndWorkOrder: PropTypes.func,
  onRejectNewOrder: PropTypes.func,
  onReviewWorkOrder: PropTypes.func,
  onUploadImage: PropTypes.func,
  onDeleteImage: PropTypes.func,
}

const mapStateToProps = ({ order, login }) => ({
  loading: order.isLoading,
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  username: login.username,
  orderDetail: order.orderDetail,
})

const mapDispatchToProps = (dispatch) => ({
  onGetOrderByKey: (org, tenant, orderKey) =>
    dispatch(getOrderByKey(org, tenant, orderKey)),
  onApproveOrder: (org, tenentId, orderKey) =>
    dispatch(approveOrder(org, tenentId, orderKey)),
  onAssignOrder: (org, tenentId, orderKey, targetEmployeeKey) =>
    dispatch(assignWork(org, tenentId, orderKey, targetEmployeeKey)),
  onStartWorkOrder: (org, tenentId, orderKey) =>
    dispatch(startWorkOrder(org, tenentId, orderKey)),
  onEndWorkOrder: (org, tenentId, orderKey) =>
    dispatch(endWorkOrder(org, tenentId, orderKey)),
  onRejectNewOrder: (org, tenentId, orderKey, message) =>
    dispatch(rejectNewOrder(org, tenentId, orderKey, message)),
  onReviewWorkOrder: (org, tenentId, orderKey) =>
    dispatch(reviewWorkOrder(org, tenentId, orderKey)),
  onUploadImage: (org, tenantId, orderKey, vin, original, thumbnail) =>
    dispatch(uploadImage(org, tenantId, orderKey, vin, original, thumbnail)),
  onDeleteImage: (org, tenantId, orderKey, vin, imageId) =>
    dispatch(deleteImage(org, tenantId, orderKey, vin, imageId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ServiceOrderDetail))
