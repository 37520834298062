import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CONFIRM_REGISTRATION,
  CONFIRM_REGISTRATION_SUCCESS,
  CONFIRM_REGISTRATION_FAIL,
  RESEND_REGISTRATION_CODE,
  RESEND_REGISTRATION_CODE_SUCCESS,
  RESEND_REGISTRATION_CODE_FAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  CONFIRM_NEW_PASSWORD,
  CONFIRM_NEW_PASSWORD_SUCCESS,
  CONFIRM_NEW_PASSWORD_FAIL,
  START_CHANGE_PASSWORD,
  RESET_ALL,
} from './actionTypes'

const initialState = {
  loading: false,
  codeRequested: false,
  passwordChanged: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case START_CHANGE_PASSWORD:
      console.log(
        'reducer::LOGIN_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )
      state = {
        ...state,
        loading: false,
        error: null,
      }
      break
    case LOGIN:
      console.log(
        'reducer::LOGIN action.payload=' + JSON.stringify(action.payload)
      )

      state = {
        loading: true,
      }

      break

    case LOGIN_SUCCESS:
      console.log(
        'reducer::LOGIN_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        loading: false,
        jwtToken: action.payload.loggedInUser.jwtToken,
        username: action.payload.loggedInUser.username,
        firstName: action.payload.loggedInUser.firstName,
        lastName: action.payload.loggedInUser.lastName,
        tenant: action.payload.loggedInUser.tenant,
        email: action.payload.loggedInUser.email,
        features: action.payload.loggedInUser.features
          .split(',')
          .map((item) => item.trim()),
        org: action.payload.loggedInUser.org,
      }
      break

    case LOGIN_FAIL:
      console.log(
        'reducer::LOGIN_FAIL action.payload=' + JSON.stringify(action.payload)
      )

      if (
        action.payload.error.code == 'UserNotConfirmedException' ||
        action.payload.error.code == 'PasswordResetRequiredException'
      ) {
        state = {
          error: action.payload.error,
          loading: false,
        }
      } else {
        state = {
          error: 'Login Failed',
          loading: false,
        }
      }

      break

    case CHANGE_PASSWORD:
      console.log(
        'reducer::CHANGE_PASSWORD action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        changePasswordStatus: 'INITIATED',
        loading: true,
      }

      break

    case CHANGE_PASSWORD_SUCCESS:
      console.log(
        'reducer::CHANGE_PASSWORD_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        changePasswordStatus: 'SUCCESS',
        loading: false,
      }
      break

    case CHANGE_PASSWORD_FAIL:
      console.log(
        'reducer::CHANGE_PASSWORD_FAIL action.payload=' +
          JSON.stringify(action.payload)
      )
      state = {
        ...state,
        error: action.payload.error.message,
        changePasswordStatus: 'FAILED',
        loading: false,
      }
      break

    case LOGOUT:
      console.log(
        'reducer::LOGOUT action.payload=' + JSON.stringify(action.payload)
      )
      state = initialState
      break

    case LOGOUT_SUCCESS:
      console.log(
        'reducer::LOGOUT_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )
      state = initialState
      break

    case LOGOUT_FAIL:
      console.log(
        'reducer::LOGOUT_FAIL action.payload=' + JSON.stringify(action.payload)
      )
      state = initialState
      break

    case CONFIRM_REGISTRATION:
      console.log(
        'reducer::CONFIRM_REGISTRATION action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        loading: true,
        userConfirmStatus: 'INIT',
      }

      break

    case CONFIRM_REGISTRATION_SUCCESS:
      console.log(
        'reducer::CONFIRM_REGISTRATION_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        loading: false,
        userConfirmStatus: 'CONFIRMED',
      }
      break

    case CONFIRM_REGISTRATION_FAIL:
      console.log(
        'reducer::CONFIRM_REGISTRATION_FAIL action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        error: 'Confirm Failed',
        loading: false,
        userConfirmStatus: 'NOT_CONFIRMED',
      }
      break

    case RESET_ALL:
      console.log('reducer::RESET_ALL')

      state = initialState
      break

    case RESEND_REGISTRATION_CODE:
      console.log(
        'reducer::RESEND_REGISTRATION_CODE action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        loading: true,
      }

      break

    case RESEND_REGISTRATION_CODE_SUCCESS:
      console.log(
        'reducer::RESEND_REGISTRATION_CODE_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        error: 'CodeResentException',
        loading: false,
      }
      break

    case RESEND_REGISTRATION_CODE_FAIL:
      console.log(
        'reducer::RESEND_REGISTRATION_CODE_FAIL action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        error: 'Cannot send the registration code',
        loading: false,
      }
      break

    case FORGOT_PASSWORD:
      console.log(
        'reducer::FORGOT_PASSWORD action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        loading: true,
        passwordChanged: false,
        codeRequested: false,
      }

      break

    case FORGOT_PASSWORD_SUCCESS:
      console.log(
        'reducer::FORGOT_PASSWORD_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        loading: false,
        codeRequested: true,
      }
      break

    case FORGOT_PASSWORD_FAIL:
      console.log(
        'reducer::FORGOT_PASSWORD_FAIL action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        error: action.payload,
        loading: false,
        codeRequested: false,
      }
      break

    case CONFIRM_NEW_PASSWORD:
      console.log(
        'reducer::CONFIRM_NEW_PASSWORD action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        loading: true,
      }

      break

    case CONFIRM_NEW_PASSWORD_SUCCESS:
      console.log(
        'reducer::CONFIRM_NEW_PASSWORD_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        loading: false,
        passwordChanged: true,
      }
      break

    case CONFIRM_NEW_PASSWORD_FAIL:
      console.log(
        'reducer::CONFIRM_NEW_PASSWORD_FAIL action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        error: action.payload,
        loading: false,
      }
      break

    default:
      break
  }
  return state
}

export default login
