/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Row, Col, Input } from 'reactstrap'

import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import '../../../assets/scss/custom/style.css'
import '../ServicePriority/selectservice.scss'

import { Controller, useFormContext, useWatch } from 'react-hook-form'

import {
  isToday,
  isDateBeforeToday,
  isTomrrow,
  isLate,
} from '../../../helpers/date_formatter'

import GlistenSelectableButton from '../../../components/GlistenSelectableButton'
import GlistenDropdown from '../../../components/GlistenDropdown'
import { SERVICE_PRIORITY, OPERATION_TIMES } from 'common/contants'
import { sendPageViewEvent } from '../../../firebase'

const ServicePriority = (props) => {
  const methods = useFormContext()
  const { control } = methods

  useEffect(() => {
    sendPageViewEvent('Select Service Priority')
  }, [])

  const [todaySelCheck, settodaySelCheck] = useState(false)

  const adjustTimeOptions = (date) => {
    console.log(date)
    if (isToday(date)) {
      //user selected today's date, wipe selection if any, don't display times in past in dropdown
    } else {
      //user selected future today, wipe selection if any, display all time slots in dropdown
    }
  }

  var calDateClicked = methods.watch('date')
  console.log(calDateClicked)

  calDateClicked != null ? adjustTimeOptions(calDateClicked) : null

  return (
    <>
      <Row style={{ marginTop: '0rem' }}>
        <Col lg={4}>
          <GlistenDropdown
            formRegistragionName='priority'
            placeholder={props.t('Select order priority')}
            items={SERVICE_PRIORITY}
            value=''
            validationMsg={props.t('Select a priority')}
          />
        </Col>
        {/* {SERVICE_PRIORITY.map((item) => (
          <Col
            key={item.key}
            lg={2}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Controller
              control={control}
              name='priority'
              render={({ field }) => (
                <GlistenSelectableButton
                  img={item.image}
                  title={props.t(item.title)}
                  selected={field.value}
                  value={item.key}
                  setSelected={(e) => {
                    field.onChange(e.value)
                  }}
                />
              )}
            />
          </Col>
        ))} */}
      </Row>
      <Row style={{ marginTop: '3rem' }}>
        <Col lg={12}>
          <h4 className='secondary-modal-title'>
            {props.t('Select a pick-up date and time')}
          </h4>
        </Col>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col lg={6} style={{ padding: '0' }}>
          <Controller
            control={control}
            name='date'
            render={({ field }) => (
              <DayPicker
                disabledDays={[{ before: new Date() }]}
                fromMonth={new Date()}
                onDayClick={(e) => field.onChange(e)}
                selectedDays={field.value}
              />
            )}
          />
        </Col>

        <Col lg={4}>
          <div style={{ marginTop: '1rem' }}>
            <h6 className={'subsection-title'}>{props.t('Time')}</h6>
            <GlistenDropdown
              nonEditable={calDateClicked == null}
              formRegistragionName='time'
              placeholder={props.t('Select pick up time')}
              items={OPERATION_TIMES}
              value=''
              validationMsg={props.t('Select a time')}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

ServicePriority.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ServicePriority))
