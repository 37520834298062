import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import React, { useRef, useEffect } from 'react'

import { Row, Col, Card, Alert, Container, Label } from 'reactstrap'
import '../../pages/Authentication/login.scss'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { forgotPassword, confirmPassword, resetAll } from '../../store/actions'
import { useForm } from 'react-hook-form'

// import images
import logo from 'assets/images/glisten/glisten-logo.svg'

const ForgotPassword = (props) => {
  const formRef = useRef()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const password = useRef({})
  password.current = watch('newPassword', '')
  const watchCode = watch('newcode')
  const watchPassword = watch('newPassword')
  const watchConfirmPassword = watch('confirmpassword')
  const watchEmail = watch('email')

  const onSubmit = (data) => {
    if (!props.codeRequested) {
      props.onForgotPassword(data.email)
    } else {
      props.onConfirmPassword(data.email, data.newcode, data.newPassword)
    }
  }

  useEffect(() => {
    props.onReset()
    if (!isMobile) {
      document.body.style.backgroundColor = 'black'
      return () => (document.body.style.backgroundColor = 'white')
    }
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>{props.t('Login')}</title>
      </MetaTags>

      <div className='home-btn d-none d-sm-block'>
        <Link to='/' className='text-dark'>
          <i className='fas fa-home h2' />
        </Link>
      </div>

      <div className='account-pages top-page-margin'>
        <Container>
          {/* Show card version on screens larger than mobile size */}
          {!isMobile && (
            <Row className='justify-content-center d-none d-sm-flex '>
              <Col md={8} lg={6} xl={6}>
                <Card className='overflow-hidden'>
                  <div>
                    <Row>
                      <Col xs={12}>
                        <div>
                          <img
                            className='login-logo center-block d-block mx-auto'
                            src={logo}
                            alt=''
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className='p-0 '>
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                      {props.passwordChanged && (
                        <Alert color='info' style={{ textAlign: 'center' }}>
                          {props.t(
                            'Your Password has been updated. Please click `Continue` and login with new password.'
                          )}
                        </Alert>
                      )}
                      {props.error && (
                        <Alert color='danger' style={{ textAlign: 'center' }}>
                          {props.t(props.error.error.message)}
                        </Alert>
                      )}
                      {props.error == null && props.codeRequested == false && (
                        <Alert color='info' style={{ textAlign: 'center' }}>
                          {props.t(
                            'Please enter your email address associated with your account.'
                          )}
                        </Alert>
                      )}
                      {props.error == null && props.codeRequested == true && (
                        <Alert color='info' style={{ textAlign: 'center' }}>
                          {props.t(
                            'Please enter the verification code found from you inbox and new password.'
                          )}
                        </Alert>
                      )}
                      <div className='form-horizontal'>
                        {!props.codeRequested && (
                          <div className='login-email-field'>
                            <Label
                              className={
                                watchEmail == null || watchEmail.length == 0
                                  ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                  : 'glisten-textfield-label'
                              }
                              for='email'
                            >
                              {props.t('Email Address')}
                            </Label>
                            <br />

                            <input
                              id='email'
                              name='email'
                              label={props.t('Email')}
                              className='form-control glisten-textfield'
                              placeholder={props.t('Email Address')}
                              defaultValue=''
                              {...register('email', {
                                required: props.t(
                                  'Please enter correct email address.'
                                ),
                                pattern: {
                                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: 'Please enter a valid email',
                                },
                              })}
                            />
                            {errors.email?.message && (
                              <p>{errors.email.message}</p>
                            )}
                          </div>
                        )}
                        {!props.passwordChanged && props.codeRequested && (
                          <div className='login-email-field'>
                            <Label
                              className={
                                watchCode == null || watchCode.length == 0
                                  ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                  : 'glisten-textfield-label'
                              }
                              for='newcode'
                            >
                              {props.t('Verification Code')}
                            </Label>
                            <br />

                            <input
                              id='newcode'
                              name='newcode'
                              label={props.t('Verification Code')}
                              className='form-control glisten-textfield'
                              placeholder={props.t('Verification Code')}
                              defaultValue=''
                              {...register('newcode', {
                                required: props.t('Please enter 6 digit code.'),
                                //   pattern: {
                                //     value: /(?<!\d)\d{6}(?!\d)/,
                                //     message:
                                //       'Confirmation code must contain 6 digit numbers',
                                //   },
                              })}
                            />
                            {errors.code?.message && (
                              <p>{errors.code.message}</p>
                            )}
                          </div>
                        )}
                        {!props.passwordChanged && props.codeRequested && (
                          <div className='login-email-field'>
                            <Label
                              className={
                                watchPassword == null ||
                                watchPassword.length == 0
                                  ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                  : 'glisten-textfield-label'
                              }
                              for='newPassword'
                            >
                              {props.t('New Password')}
                            </Label>
                            <br />

                            <input
                              type='password'
                              id='newPassword'
                              name='newPassword'
                              label={props.t('New Password')}
                              className='form-control glisten-textfield'
                              placeholder={props.t('New Password')}
                              defaultValue=''
                              {...register('newPassword', {
                                required: props.t('Please enter new password.'),
                              })}
                            />
                            {errors.new?.message && <p>{errors.new.message}</p>}
                          </div>
                        )}
                        {!props.passwordChanged && props.codeRequested && (
                          <div className='login-email-field'>
                            <Label
                              className={
                                watchConfirmPassword == null ||
                                watchConfirmPassword.length == 0
                                  ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                  : 'glisten-textfield-label'
                              }
                              for='confirmpassword'
                            >
                              {props.t('Confirm New Password')}
                            </Label>
                            <br />

                            <input
                              type='password'
                              id='confirmpassword'
                              name='confirmpassword'
                              label={props.t('Confirm New Password')}
                              className='form-control glisten-textfield'
                              placeholder={props.t('Confirm New Password')}
                              defaultValue=''
                              {...register('confirmpassword', {
                                required: props.t(
                                  'Please enter new password again.'
                                ),
                                validate: (value) =>
                                  value === password.current ||
                                  'The passwords do not match',
                              })}
                            />
                            {errors.confirmpassword?.message && (
                              <p>{errors.confirmpassword.message}</p>
                            )}
                          </div>
                        )}
                        <Row>
                          <Col
                            lg={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {props.passwordChanged && (
                              <div className='d-grid'>
                                <button
                                  className='login-btn'
                                  style={{
                                    paddingLeft: '6.5rem',
                                    paddingRight: '6.5rem',
                                  }}
                                  onClick={() => {
                                    props.history.push('/login')
                                  }}
                                >
                                  {props.t('Continue')}
                                </button>
                              </div>
                            )}
                            {!props.passwordChanged && (
                              <input
                                className='login-btn'
                                style={{
                                  paddingLeft: '6.5rem',
                                  paddingRight: '6.5rem',
                                  marginBottom: '2.5rem',
                                }}
                                type='submit'
                                value={props.t('Confirm')}
                              />
                            )}
                          </Col>
                        </Row>
                        <br />
                      </div>
                    </form>
                  </div>
                </Card>
              </Col>
            </Row>
          )}

          {/* For mobile size screens */}
          {isMobile && (
            <Row className='justify-content-center d-xs-flex d-sm-none '>
              <Col xs={12}>
                <div>
                  <Row>
                    <Col xs={12}>
                      <div>
                        <img
                          className='login-logo center-block d-block mx-auto'
                          src={logo}
                          alt=''
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className='p-0 '>
                  <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                    {props.passwordChanged && (
                      <Alert color='info' style={{ textAlign: 'center' }}>
                        {props.t(
                          'Your Password has been updated. Please click `Continue` and login with new password.'
                        )}
                      </Alert>
                    )}
                    {props.error && (
                      <Alert color='danger' style={{ textAlign: 'center' }}>
                        {props.t(props.error.error.message)}
                      </Alert>
                    )}
                    {props.error == null && props.codeRequested == false && (
                      <Alert color='info' style={{ textAlign: 'center' }}>
                        {props.t(
                          'Please enter your email address associated with your account.'
                        )}
                      </Alert>
                    )}
                    {props.error == null && props.codeRequested == true && (
                      <Alert color='info' style={{ textAlign: 'center' }}>
                        {props.t(
                          'Please enter the verification code found from you inbox and new password.'
                        )}
                      </Alert>
                    )}
                    <div className='form-horizontal'>
                      {!props.codeRequested && (
                        <div className='login-email-field'>
                          <Label
                            className={
                              watchEmail == null || watchEmail.length == 0
                                ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                : 'glisten-textfield-label'
                            }
                            for='email'
                          >
                            {props.t('Email Address')}
                          </Label>
                          <br />

                          <input
                            id='email'
                            name='email'
                            label={props.t('Email')}
                            className='form-control glisten-textfield'
                            placeholder={props.t('Email Address')}
                            defaultValue=''
                            {...register('email', {
                              required: props.t(
                                'Please enter correct email address.'
                              ),
                              pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                              },
                            })}
                          />
                          {errors.email?.message && (
                            <p>{errors.email.message}</p>
                          )}
                        </div>
                      )}
                      {!props.passwordChanged && props.codeRequested && (
                        <div className='login-email-field'>
                          <Label
                            className={
                              watchCode == null || watchCode.length == 0
                                ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                : 'glisten-textfield-label'
                            }
                            for='newcode'
                          >
                            {props.t('Verification Code')}
                          </Label>
                          <br />

                          <input
                            id='newcode'
                            name='newcode'
                            label={props.t('Verification Code')}
                            className='form-control glisten-textfield'
                            placeholder={props.t('Verification Code')}
                            defaultValue=''
                            {...register('newcode', {
                              required: props.t('Please enter 6 digit code.'),
                              //   pattern: {
                              //     value: /(?<!\d)\d{6}(?!\d)/,
                              //     message:
                              //       'Confirmation code must contain 6 digit numbers',
                              //   },
                            })}
                          />
                          {errors.code?.message && <p>{errors.code.message}</p>}
                        </div>
                      )}
                      {!props.passwordChanged && props.codeRequested && (
                        <div className='login-email-field'>
                          <Label
                            className={
                              watchPassword == null || watchPassword.length == 0
                                ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                : 'glisten-textfield-label'
                            }
                            for='newPassword'
                          >
                            {props.t('New Password')}
                          </Label>
                          <br />

                          <input
                            type='password'
                            id='newPassword'
                            name='newPassword'
                            label={props.t('New Password')}
                            className='form-control glisten-textfield'
                            placeholder={props.t('New Password')}
                            defaultValue=''
                            {...register('newPassword', {
                              required: props.t('Please enter new password.'),
                            })}
                          />
                          {errors.new?.message && <p>{errors.new.message}</p>}
                        </div>
                      )}
                      {!props.passwordChanged && props.codeRequested && (
                        <div className='login-email-field'>
                          <Label
                            className={
                              watchConfirmPassword == null ||
                              watchConfirmPassword.length == 0
                                ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                : 'glisten-textfield-label'
                            }
                            for='confirmpassword'
                          >
                            {props.t('Confirm New Password')}
                          </Label>
                          <br />

                          <input
                            type='password'
                            id='confirmpassword'
                            name='confirmpassword'
                            label={props.t('Confirm New Password')}
                            className='form-control glisten-textfield'
                            placeholder={props.t('Confirm New Password')}
                            defaultValue=''
                            {...register('confirmpassword', {
                              required: props.t(
                                'Please enter new password again.'
                              ),
                              validate: (value) =>
                                value === password.current ||
                                'The passwords do not match',
                            })}
                          />
                          {errors.confirmpassword?.message && (
                            <p>{errors.confirmpassword.message}</p>
                          )}
                        </div>
                      )}
                      <Row>
                        <Col
                          lg={12}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          {props.passwordChanged && (
                            <div className='d-grid'>
                              <button
                                className='login-btn'
                                style={{
                                  paddingLeft: '6.5rem',
                                  paddingRight: '6.5rem',
                                }}
                                onClick={() => {
                                  props.history.push('/login')
                                }}
                              >
                                {props.t('Continue')}
                              </button>
                            </div>
                          )}
                          {!props.passwordChanged && (
                            <input
                              className='login-btn'
                              style={{
                                paddingLeft: '6.5rem',
                                paddingRight: '6.5rem',
                                marginBottom: '2.5rem',
                              }}
                              type='submit'
                              value={props.t('Confirm')}
                            />
                          )}
                        </Col>
                      </Row>
                      <br />
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgotPassword.propTypes = {
  t: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  onForgotPassword: PropTypes.func,
  onConfirmPassword: PropTypes.func,
  onReset: PropTypes.func,
  passwordChanged: PropTypes.bool,
  codeRequested: PropTypes.bool,
}

const mapStateToProps = ({ login }) => ({
  error: login.error,
  passwordChanged: login.passwordChanged,
  codeRequested: login.codeRequested,
})

const mapDispatchToProps = (dispatch) => ({
  onConfirmPassword: (username, code, newPassword) =>
    dispatch(confirmPassword(username, code, newPassword)),
  onForgotPassword: (username) => dispatch(forgotPassword(username)),
  onReset: () => dispatch(resetAll()),
})

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword))
)
