/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import React, { useRef, useEffect, useState } from 'react'

import { Row, Col, Card, Alert, Container, Label, Button } from 'reactstrap'
import '../../pages/Authentication/login.scss'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import {
  login,
  confirmRegistration,
  resendConfirmationCode,
  resetAll,
} from '../../store/actions'
import { useForm } from 'react-hook-form'

// import images
import logo from 'assets/images/glisten/glisten-logo.svg'

const Login = (props) => {
  const formRef = useRef()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const [userNotConfirmed, setUserNotConfirmed] = useState(false)

  const watchEmail = watch('email')
  const watchPassword = watch('password')
  const watchCode = watch('code')

  const reloadPage = () => {
    setUserNotConfirmed(false)
    props.onReset()
  }

  const onSubmit = (data) => {
    if (userNotConfirmed) {
      props.onConfirmRegistration(data.email, data.code)
    } else {
      props.onLogin(
        process.env.REACT_APP_BACKEND_ENV === 'local'
          ? data.email.replace('.', '').replace('@', '')
          : data.email,
        data.password
      )
    }
  }

  useEffect(() => {
    props.onReset()
    if (!isMobile) {
      document.body.style.backgroundColor = 'black'
      return () => (document.body.style.backgroundColor = 'white')
    }
  }, [])

  if (props.jwtToken != null) {
    console.log(props.userFeat)
    if (
      props.userFeat.includes('ftl-executive') ||
      props.userFeat.includes('ftl-admin')
    )
      props.history.push('/dashboard')
    else props.history.push('/whiteboard')
  }

  if (
    userNotConfirmed == false &&
    props.error &&
    typeof props.error !== 'string'
  ) {
    if (props.error.code == 'UserNotConfirmedException') {
      setUserNotConfirmed(true)
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>{props.t('Login')}</title>
      </MetaTags>
      <div className='home-btn d-none d-sm-block'>
        <Link to='/' className='text-dark'>
          <i className='fas fa-home h2' />
        </Link>
      </div>
      <div className='account-pages top-page-margin'>
        <Container>
          {/* FOR SCREENS LARGER THAN MOBILE SIZE */}
          {!isMobile && (
            <Row className='justify-content-center d-none d-sm-flex '>
              <Col md={8} lg={6} xl={6}>
                <Card className='overflow-hidden'>
                  <div>
                    <Row>
                      <Col xs={12}>
                        <div>
                          <img
                            className='login-logo center-block d-block mx-auto'
                            src={logo}
                            alt=''
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className='p-0 '>
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                      {/* TO DO: Rewrite so Invalid email/password isn't a blanket error catch all*/}
                      {props.error &&
                      typeof props.error === 'string' &&
                      props.error == 'Login Failed' ? (
                        <Alert color='danger' style={{ textAlign: 'center' }}>
                          {props.t('Invalid email/password')}
                        </Alert>
                      ) : null}

                      {props.error &&
                      props.error.code == 'UserNotConfirmedException' ? (
                        <Alert color='warning' style={{ textAlign: 'center' }}>
                          {props.t(
                            'User must confirm registered email before using the application. Please check your registered email address to get the code.'
                          )}
                        </Alert>
                      ) : null}

                      {props.error && props.error == 'Confirm Failed' ? (
                        <Alert color='danger' style={{ textAlign: 'center' }}>
                          {props.t(
                            'Incorrect code. Please check your registered email address to get and reenter the correct code. Or click resend to receive a new code.'
                          )}
                        </Alert>
                      ) : null}

                      {props.error && props.error == 'CodeResentException' ? (
                        <Alert color='warning' style={{ textAlign: 'center' }}>
                          {props.t(
                            'New confirmation code sent. Please check your registered email address to get the code. User must confirm registered email before using the application.'
                          )}
                        </Alert>
                      ) : null}

                      {props.error &&
                      props.error.code == 'PasswordResetRequiredException' ? (
                        <Alert color='danger' style={{ textAlign: 'center' }}>
                          {props.t(
                            'Password reset is required for this account. Please click `Forgot Password?` below and reset your password.'
                          )}
                        </Alert>
                      ) : null}

                      {props.userConfirmStatus == 'CONFIRMED' ? (
                        <Alert color='success' style={{ textAlign: 'center' }}>
                          {props.t(
                            'Your account is now confirmed. Please try to login again with the password provided by your boss.'
                          )}
                        </Alert>
                      ) : null}

                      <div className='form-horizontal'>
                        {userNotConfirmed &&
                        props.userConfirmStatus != 'CONFIRMED' ? (
                          <div className='login-email-field'>
                            <Label
                              className={
                                watchCode == null || watchCode.length == 0
                                  ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                  : 'glisten-textfield-label'
                              }
                              for='code'
                            >
                              {props.t('Code')}
                            </Label>
                            <br />

                            <input
                              id='code'
                              name='code'
                              label={props.t('Confirmation Code')}
                              className='form-control glisten-textfield'
                              placeholder={props.t('Confirmation Code')}
                              defaultValue=''
                              {...register('code', {
                                required: props.t('Please enter 6 digit code.'),
                                //   pattern: {
                                //     value: /(?<!\d)\d{6}(?!\d)/,
                                //     message:
                                //       'Confirmation code must contain 6 digit numbers',
                                //   },
                              })}
                            />
                            {errors.code?.message && (
                              <p>{errors.code.message}</p>
                            )}
                          </div>
                        ) : (
                          <>
                            {props.userConfirmStatus != 'CONFIRMED' && (
                              <div className='login-email-field'>
                                <Label
                                  className={
                                    watchEmail == null || watchEmail.length == 0
                                      ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                      : 'glisten-textfield-label'
                                  }
                                  for='email'
                                >
                                  {props.t('Email Address')}
                                </Label>
                                <br />

                                <input
                                  id='email'
                                  name='email'
                                  label={props.t('Email')}
                                  className='form-control glisten-textfield'
                                  placeholder={props.t('Email Address')}
                                  defaultValue=''
                                  {...register('email', {
                                    required: props.t(
                                      'Please enter correct email address.'
                                    ),
                                    pattern: {
                                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      message: 'Please enter a valid email',
                                    },
                                  })}
                                />
                                {errors.email?.message && (
                                  <p>{errors.email.message}</p>
                                )}
                              </div>
                            )}

                            {props.userConfirmStatus != 'CONFIRMED' && (
                              <div className='login-pass-field'>
                                <Label
                                  className={
                                    watchPassword == null ||
                                    watchPassword.length == 0
                                      ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                      : 'glisten-textfield-label'
                                  }
                                  for='password'
                                >
                                  {props.t('Password')}
                                </Label>
                                <br />
                                <input
                                  className='form-control glisten-textfield'
                                  id='password'
                                  name='password'
                                  label='Password'
                                  type='password'
                                  placeholder={props.t('Password')}
                                  defaultValue=''
                                  {...register('password', {
                                    required: props.t('Please enter password'),
                                  })}
                                />
                                {errors.password?.message && (
                                  <p>{errors.password.message}</p>
                                )}
                              </div>
                            )}
                          </>
                        )}

                        {userNotConfirmed &&
                        props.userConfirmStatus == 'CONFIRMED' ? (
                          <div
                            style={{ marginRight: '2em', marginLeft: '2em' }}
                          >
                            <Row>
                              <Col
                                lg={12}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <button
                                  className='login-btn'
                                  onClick={() => reloadPage()}
                                  style={{
                                    paddingLeft: '6.5rem',
                                    paddingRight: '6.5rem',
                                  }}
                                >
                                  {props.t('Continue')}
                                </button>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <div
                            style={{ marginRight: '2em', marginLeft: '2em' }}
                          >
                            <Row>
                              <Col
                                lg={12}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <input
                                  className='login-btn'
                                  type='submit'
                                  style={{
                                    paddingLeft: '6.5rem',
                                    paddingRight: '6.5rem',
                                  }}
                                  value={
                                    userNotConfirmed
                                      ? props.t('Confirm')
                                      : props.t('Log In')
                                  }
                                />
                              </Col>
                            </Row>
                          </div>
                        )}

                        {!userNotConfirmed &&
                          props.userConfirmStatus != 'CONFIRMED' && (
                            <div className='d-grid'>
                              <button
                                className='btn login-link'
                                style={{
                                  paddingLeft: '6.5rem',
                                  paddingRight: '6.5rem',
                                }}
                                onClick={() =>
                                  props.history.push('/forgotpassword')
                                }
                              >
                                {props.t('Forgot Password?')}
                              </button>
                            </div>
                          )}
                        {userNotConfirmed &&
                          props.userConfirmStatus != 'CONFIRMED' && (
                            <div className='d-grid'>
                              <button
                                className='btn login-link'
                                style={{
                                  paddingLeft: '6.5rem',
                                  paddingRight: '6.5rem',
                                }}
                                onClick={() =>
                                  props.onResendConfirmationCode(watchEmail)
                                }
                              >
                                {props.t('Resend Code')}
                              </button>
                            </div>
                          )}
                        <br />
                      </div>
                    </form>
                  </div>
                </Card>
              </Col>
            </Row>
          )}

          {/* FOR MOBILE SIZED SCREENS */}
          {isMobile && (
            <Row className='justify-content-center d-xs-flex d-sm-none '>
              <Col xs={12}>
                <div>
                  <Row>
                    <Col xs={12}>
                      <div>
                        <img
                          className='login-logo center-block d-block mx-auto'
                          src={logo}
                          alt=''
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className='p-0 '>
                  <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                    {/* TO DO: Rewrite so Invalid email/password isn't a blanket error catch all*/}
                    {props.error &&
                    typeof props.error === 'string' &&
                    props.error == 'Login Failed' ? (
                      <Alert color='danger' style={{ textAlign: 'center' }}>
                        {props.t('Invalid email/password')}
                      </Alert>
                    ) : null}

                    {props.error &&
                    props.error.code == 'UserNotConfirmedException' ? (
                      <Alert color='warning' style={{ textAlign: 'center' }}>
                        {props.t(
                          'User must confirm registered email before using the application. Please check your registered email address to get the code.'
                        )}
                      </Alert>
                    ) : null}

                    {props.error && props.error == 'Confirm Failed' ? (
                      <Alert color='danger' style={{ textAlign: 'center' }}>
                        {props.t(
                          'Incorrect code. Please check your registered email address to get and reenter the correct code. Or click resend to receive a new code.'
                        )}
                      </Alert>
                    ) : null}

                    {props.error && props.error == 'CodeResentException' ? (
                      <Alert color='warning' style={{ textAlign: 'center' }}>
                        {props.t(
                          'New confirmation code sent. Please check your registered email address to get the code. User must confirm registered email before using the application.'
                        )}
                      </Alert>
                    ) : null}

                    {props.error &&
                    props.error.code == 'PasswordResetRequiredException' ? (
                      <Alert color='danger' style={{ textAlign: 'center' }}>
                        {props.t(
                          'Password reset is required for this account. Please click `Forgot Password?` below and reset your password.'
                        )}
                      </Alert>
                    ) : null}

                    {props.userConfirmStatus == 'CONFIRMED' ? (
                      <Alert color='success' style={{ textAlign: 'center' }}>
                        {props.t(
                          'Your account is now confirmed. Please try to login again with the password provided by your boss.'
                        )}
                      </Alert>
                    ) : null}

                    <div className='form-horizontal'>
                      {userNotConfirmed &&
                      props.userConfirmStatus != 'CONFIRMED' ? (
                        <div className='login-email-field'>
                          <Label
                            className={
                              watchCode == null || watchCode.length == 0
                                ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                : 'glisten-textfield-label'
                            }
                            for='code'
                          >
                            {props.t('Code')}
                          </Label>
                          <br />

                          <input
                            id='code'
                            name='code'
                            label={props.t('Confirmation Code')}
                            className='form-control glisten-textfield'
                            placeholder={props.t('Confirmation Code')}
                            defaultValue=''
                            {...register('code', {
                              required: props.t('Please enter 6 digit code.'),
                              //   pattern: {
                              //     value: /(?<!\d)\d{6}(?!\d)/,
                              //     message:
                              //       'Confirmation code must contain 6 digit numbers',
                              //   },
                            })}
                          />
                          {errors.code?.message && <p>{errors.code.message}</p>}
                        </div>
                      ) : (
                        <>
                          {props.userConfirmStatus != 'CONFIRMED' && (
                            <div className='login-email-field'>
                              <Label
                                className={
                                  watchEmail == null || watchEmail.length == 0
                                    ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                    : 'glisten-textfield-label'
                                }
                                for='email'
                              >
                                {props.t('Email Address')}
                              </Label>
                              <br />

                              <input
                                id='email'
                                name='email'
                                label={props.t('Email')}
                                className='form-control glisten-textfield'
                                placeholder={props.t('Email Address')}
                                defaultValue=''
                                {...register('email', {
                                  required: props.t(
                                    'Please enter correct email address.'
                                  ),
                                  pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please enter a valid email',
                                  },
                                })}
                              />
                              {errors.email?.message && (
                                <p>{errors.email.message}</p>
                              )}
                            </div>
                          )}

                          {props.userConfirmStatus != 'CONFIRMED' && (
                            <div className='login-pass-field'>
                              <Label
                                className={
                                  watchPassword == null ||
                                  watchPassword.length == 0
                                    ? 'glisten-textfield-label glisten-textfield-label-hidden'
                                    : 'glisten-textfield-label'
                                }
                                for='password'
                              >
                                {props.t('Password')}
                              </Label>
                              <br />
                              <input
                                className='form-control glisten-textfield'
                                id='password'
                                name='password'
                                label='Password'
                                type='password'
                                placeholder={props.t('Password')}
                                defaultValue=''
                                {...register('password', {
                                  required: props.t('Please enter password'),
                                })}
                              />
                              {errors.password?.message && (
                                <p>{errors.password.message}</p>
                              )}
                            </div>
                          )}
                        </>
                      )}

                      {userNotConfirmed &&
                      props.userConfirmStatus == 'CONFIRMED' ? (
                        <div style={{ marginRight: '2em', marginLeft: '2em' }}>
                          <Row>
                            <Col
                              xs={12}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <button
                                className='login-btn'
                                onClick={() => reloadPage()}
                                style={{
                                  paddingLeft: '6.5rem',
                                  paddingRight: '6.5rem',
                                }}
                              >
                                {props.t('Continue')}
                              </button>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div style={{ marginRight: '2em', marginLeft: '2em' }}>
                          <Row>
                            <Col
                              xs={12}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <input
                                className='login-btn'
                                type='submit'
                                style={{
                                  paddingLeft: '6.5rem',
                                  paddingRight: '6.5rem',
                                }}
                                value={
                                  userNotConfirmed
                                    ? props.t('Confirm')
                                    : props.t('Log In')
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      )}

                      {!userNotConfirmed &&
                        props.userConfirmStatus != 'CONFIRMED' && (
                          <div className='d-grid'>
                            <button
                              className='btn login-link'
                              style={{
                                paddingLeft: '6.5rem',
                                paddingRight: '6.5rem',
                              }}
                              onClick={() =>
                                props.history.push('/forgotpassword')
                              }
                            >
                              {props.t('Forgot Password?')}
                            </button>
                          </div>
                        )}
                      {userNotConfirmed &&
                        props.userConfirmStatus != 'CONFIRMED' && (
                          <div className='d-grid'>
                            <button
                              className='btn login-link'
                              style={{
                                paddingLeft: '6.5rem',
                                paddingRight: '6.5rem',
                              }}
                              onClick={() =>
                                props.onResendConfirmationCode(watchEmail)
                              }
                            >
                              {props.t('Resend Code')}
                            </button>
                          </div>
                        )}
                      <br />
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {
  t: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  jwtToken: PropTypes.string,
  onLogin: PropTypes.func,
  userConfirmStatus: PropTypes.string,
  onConfirmRegistration: PropTypes.func,
  onResendConfirmationCode: PropTypes.func,
  onReset: PropTypes.func,
  confirmationEmailSent: PropTypes.bool,
}

const mapStateToProps = ({ login }) => ({
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  jwtToken: login.jwtToken,
  confirmationEmailSent: login.confirmationEmailSent,
  error: login.error,
  userConfirmStatus: login.userConfirmStatus,
})

const mapDispatchToProps = (dispatch) => ({
  onLogin: (username, password) => dispatch(login(username, password)),
  onConfirmRegistration: (username, code) =>
    dispatch(confirmRegistration(username, code)),
  onResendConfirmationCode: (username) =>
    dispatch(resendConfirmationCode(username)),
  onReset: () => dispatch(resetAll()),
})

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
)
