// import waiterImg from '../assets/images/illust-icons/ic-waiter.svg'
// import spotImg from '../assets/images/illust-icons/ic-spot delivery.svg'
// import eodImg from '../assets/images/illust-icons/ic-eod.svg'
// import fillImg from '../assets/images/illust-icons/ic-fill.svg'
// import apptImg from '../assets/images/illust-icons/ic-appointment.svg'

export const ROLES = [
  { key: 'Executive', value: 'Executive' },
  { key: 'Manager', value: 'Manager' },
  { key: 'Employee', value: 'Employee' },
  { key: 'Accounting', value: 'Accounting' },
  { key: 'Admin', value: 'Admin' },
]

export const STATES = [
  { key: 'AL', value: 'AL' },
  { key: 'AK', value: 'AK' },
  { key: 'AR', value: 'AR' },
  { key: 'AZ', value: 'AZ' },
  { key: 'CA', value: 'CA' },
  { key: 'CO', value: 'CO' },
  { key: 'CT', value: 'CT' },
  { key: 'DC', value: 'DC' },
  { key: 'DE', value: 'DE' },
  { key: 'FL', value: 'FL' },
  { key: 'GA', value: 'GA' },
  { key: 'HI', value: 'HI' },
  { key: 'IA', value: 'IA' },
  { key: 'ID', value: 'ID' },
  { key: 'IL', value: 'IL' },
  { key: 'IN', value: 'IN' },
  { key: 'KS', value: 'KS' },
  { key: 'KY', value: 'KY' },
  { key: 'LA', value: 'LA' },
  { key: 'MA', value: 'MA' },
  { key: 'MD', value: 'MD' },
  { key: 'ME', value: 'ME' },
  { key: 'MI', value: 'MI' },
  { key: 'MN', value: 'MN' },
  { key: 'MO', value: 'MO' },
  { key: 'MS', value: 'MS' },
  { key: 'MT', value: 'MT' },
  { key: 'NC', value: 'NC' },
  { key: 'NE', value: 'NE' },
  { key: 'NH', value: 'NH' },
  { key: 'NJ', value: 'NJ' },
  { key: 'NM', value: 'NM' },
  { key: 'NV', value: 'MV' },
  { key: 'NY', value: 'NY' },
  { key: 'ND', value: 'ND' },
  { key: 'OH', value: 'OH' },
  { key: 'OK', value: 'OK' },
  { key: 'OR', value: 'OR' },
  { key: 'PA', value: 'PA' },
  { key: 'RI', value: 'RI' },
  { key: 'SC', value: 'SC' },
  { key: 'SD', value: 'SD' },
  { key: 'TN', value: 'TN' },
  { key: 'TX', value: 'TX' },
  { key: 'UT', value: 'UT' },
  { key: 'VT', value: 'VT' },
  { key: 'VA', value: 'VA' },
  { key: 'WA', value: 'WA' },
  { key: 'WI', value: 'WI' },
  { key: 'WV', value: 'WV' },
  { key: 'WY', value: 'WY' },
]

// export const SERVICE_PRIORITY = [
//   {
//     key: 1,
//     title: 'WAITER',
//     image: waiterImg,
//   },
//   {
//     key: 2,
//     title: 'SPOT DELIVERY',
//     image: spotImg,
//   },
//   {
//     key: 3,
//     title: 'END OF DAY',
//     image: eodImg,
//   },
//   {
//     key: 4,
//     title: 'FILL',
//     image: fillImg,
//   },
//   {
//     key: 5,
//     title: 'APPOINTMENT',
//     image: apptImg,
//   },
// ]

export const SERVICE_PRIORITY = [
  { key: 1, value: 'WAITER' },
  { key: 2, value: 'SPOT DELIVERY' },
  { key: 3, value: 'END OF DAY' },
  { key: 4, value: 'FILL' },
  { key: 5, value: 'APPOINTMENT' },
]

export const VEHICLE_WHITELIST = [
  'ASTON MARTIN',
  'TESLA',
  'JAGUAR',
  'MASERATI',
  'ROLLS ROYCE',
  'TOYOTA',
  'MERCEDES-BENZ',
  'BMW',
  'BUGATTI',
  'MINI',
  'FORD',
  'LINCOLN',
  'MERCURY',
  'LOTUS',
  'CHEVROLET',
  'BUICK',
  'CADILLAC',
  'GMC',
  'MAZDA',
  'HONDA',
  'ACURA',
  'DODGE',
  'CHRYSLER',
  'NISSAN',
  'INFINITI',
  'MITSUBISHI',
  'VOLKSWAGEN',
  'VOLVO',
  'FIAT',
  'ALFA ROMEO',
  'HYUNDAI',
  'KIA',
  'LAMBORGHINI',
  'SMART',
  'SUZUKI',
  'LEXUS',
  'SUBARU',
  'MAYBACH',
  'PONTIAC',
  'ISUZU',
  'AUDI',
  'BENTLEY',
  'PORSCHE',
  'FERRARI',
  'FISKER AUTOMOTIVE',
  'SATURN',
  'DAEWOO',
  'KOENIGSEGG',
  'MCLAREN',
  'OLDSMOBILE',
  'PAGANI',
  'GENESIS',
  'PEUGEOT',
  'POLESTAR',
  'KARMA AUTOMOTIVE LLC',
]

export const ERROR_CODE_MSG_MAPPING = {
  99999: 'We cannot process your request at this time, please try again later.',
  E00000022: 'There is an order already exist with same vehicle.',
}

export const VEHICLE_ALL_YEAR = [
  { key: '2021', value: '2021' },
  { key: '2020', value: '2020' },
  { key: '2019', value: '2019' },
  { key: '2018', value: '2018' },
  { key: '2017', value: '2017' },
  { key: '2016', value: '2016' },
  { key: '2015', value: '2015' },
  { key: '2014', value: '2014' },
  { key: '2013', value: '2013' },
  { key: '2012', value: '2012' },
  { key: '2011', value: '2011' },
  { key: '2010', value: '2010' },
  { key: '2009', value: '2009' },
  { key: '2008', value: '2008' },
  { key: '2007', value: '2007' },
  { key: '2006', value: '2006' },
  { key: '2005', value: '2005' },
  { key: '2004', value: '2004' },
  { key: '2003', value: '2003' },
  { key: '2002', value: '2002' },
  { key: '2001', value: '2001' },
  { key: '2000', value: '2000' },
  { key: '1999', value: '1999' },
  { key: '1998', value: '1998' },
  { key: '1997', value: '1997' },
  { key: '1996', value: '1996' },
  { key: '1995', value: '1995' },
  { key: '1994', value: '1994' },
  { key: '1993', value: '1993' },
  { key: '1992', value: '1992' },
  { key: '1991', value: '1991' },
  { key: '1990', value: '1990' },
  { key: '1989', value: '1989' },
  { key: '1988', value: '1988' },
  { key: '1987', value: '1987' },
  { key: '1986', value: '1986' },
  { key: '1985', value: '1985' },
  { key: '1984', value: '1984' },
  { key: '1983', value: '1983' },
  { key: '1982', value: '1982' },
]

export const VEHICLE_ALL_COLOR = [
  { key: 'WHITE', value: 'White' },
  { key: 'BLACK', value: 'Black' },
  { key: 'BLUE', value: 'Blue' },
  { key: 'SILVER', value: 'Silver' },
  { key: 'GREEN', value: 'Green' },
  { key: 'GRAY', value: 'Gray' },
  { key: 'RED', value: 'Red' },
  { key: 'YELLOW', value: 'Yellow' },
]

export const OPERATION_TIMES = [
  { key: '09:00', value: '09:00 AM' },
  { key: '09:30', value: '09:30 AM' },
  { key: '10:00', value: '10:00 AM' },
  { key: '10:30', value: '10:30 AM' },
  { key: '11:00', value: '11:00 AM' },
  { key: '11:30', value: '11:30 AM' },
  { key: '12:00', value: '12:00 PM' },
  { key: '12:30', value: '12:30 PM' },
  { key: '13:00', value: '01:00 PM' },
  { key: '13:30', value: '01:30 PM' },
  { key: '14:00', value: '02:00 PM' },
  { key: '14:30', value: '02:30 PM' },
  { key: '15:00', value: '03:00 PM' },
  { key: '15:30', value: '03:30 PM' },
  { key: '16:00', value: '04:00 PM' },
  { key: '16:30', value: '04:30 PM' },
  { key: '17:00', value: '05:00 PM' },
  { key: '17:30', value: '05:30 PM' },
  { key: '18:00', value: '06:00 PM' },
  { key: '18:30', value: '06:30 PM' },
  { key: '19:00', value: '07:00 PM' },
  { key: '19:30', value: '07:30 PM' },
  { key: '20:00', value: '08:00 PM' },
]
